import React from 'react';
import { GoogleMap, StreetViewPanorama } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const MapComponent = ({
  position,
  onMapLoad,
  onStreetViewLoad,
  isStreetViewMode,
  canvasRef,
  streetViewInfoRef
}) => {
  return (
    <div className="map-wrapper">
      {/* Map container with rotated boxes inside it */}
      <div className="map-container">
        {/* Multiple rotated background boxes */}
        <div className="rotated-box-1"></div>
        <div className="rotated-box-2"></div>
        <div className="rotated-box-3"></div>
  
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={15}
          onLoad={onMapLoad}
          options={{
            scrollwheel: true,
            gestureHandling: 'greedy',
          }}
        >
          <StreetViewPanorama
            position={position}
            visible={true}
            onLoad={onStreetViewLoad}
          />
        </GoogleMap>
        <canvas
          ref={canvasRef}
          width={640}
          height={640}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            zIndex: 10,
            display: isStreetViewMode ? 'block' : 'none',
          }}
        />
      </div>
    </div>
  );
  
}

export default MapComponent;
