import React from 'react';
import SearchBar from './SearchBar';

const SidebarComponent = ({
  confState,
  iouState,
  setConfState,
  setIouState,
  handleDetect,
  handlePredictPoles,
  streetViewInfoRef,
  predictionResult,
  confRef,
  iouRef,
  onSearch,
}) => {
  return (
    <div className="sidebar">
      <SearchBar onSearch={onSearch} />

      <div className="divider"></div>

      {/* Updated Confidence Slider */}
      <div className="slider-container">
        <label className="slider-label">Conf.</label>
        <input
          type="range"
          min="0.1"
          max="1.0"
          step="0.1"
          value={confState}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            setConfState(value);
            confRef.current = value;
          }}
        />
        <span className="slider-value">{confState}</span>
      </div>
      
      {/* Updated IoU Slider */}
      <div className="slider-container">
        <label className="slider-label">IoU</label>
        <input
          type="range"
          min="0.1"
          max="1.0"
          step="0.1"
          value={iouState}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            setIouState(value);
            iouRef.current = value;
          }}
        />
        <span className="slider-value">{iouState}</span>
      </div>


    {/* Buttons Section */}
    <div className="button-container">
      <button className="action-button" onClick={handleDetect}>Detect Poles</button>
      <button className="action-button" onClick={handlePredictPoles}>Predict Pole Class</button>
    </div>

      <div className="divider"></div>

      {streetViewInfoRef.current.isVisible && streetViewInfoRef.current.panoId && (
        <table className="info-table">
          <tbody>
            <tr><th>Pano ID</th><td>{streetViewInfoRef.current.panoId}</td></tr>
            <tr><th>Heading</th><td>{streetViewInfoRef.current.heading.toFixed(1)}</td></tr>
            <tr><th>Pitch</th><td>{streetViewInfoRef.current.pitch.toFixed(1)}</td></tr>
            <tr><th>Zoom</th><td>{streetViewInfoRef.current.zoom.toFixed(1)}</td></tr>
            <tr><th>Field of View (FOV)</th><td>{streetViewInfoRef.current.fov}</td></tr>
            <tr><th>Prediction</th><td>{predictionResult.prediction}</td></tr>
            <tr><th>Confidence</th><td>{predictionResult.confidence}</td></tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SidebarComponent;
