// src/App.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useJsApiLoader } from '@react-google-maps/api';
import { API_KEY } from './config';
import './App.css';

import MapComponent from './components/MapComponent';
import SidebarComponent from './components/SidebarComponent';
import { takeScreenshot, calculateFov } from './utils/streetViewUtils';

const initialCenter = {
  lat: 35.91783442995015,
  lng: -84.14148808562597,
};


const libraries = ['places'];

function App() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: libraries,
  });

  const [predictions, setPredictions] = useState([]);
  const [predictionResult, setPredictionResult] = useState({ prediction: '', confidence: '' });
  const [confState, setConfState] = useState(0.4);
  const [iouState, setIouState] = useState(0.4);
  const [isStreetViewMode, setIsStreetViewMode] = useState(false);
  const [searchError, setSearchError] = useState(null);

  
  const confRef = useRef(0.4);
  const iouRef = useRef(0.4);
  const positionRef = useRef(initialCenter);
  const mapRef = useRef(null);
  const streetViewRef = useRef(null);
  const canvasRef = useRef(null);
  const debounceTimerRef = useRef(null);
  const streetViewInfoRef = useRef({
    panoId: null,
    isVisible: false,
    heading: 0,
    pitch: 0,
    zoom: 1,
    fov: 90,
    lat: null,
    lng: null,
  });

  const clearPredictions = useCallback(() => {
    setPredictions([]);
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, []);

  const isStreetViewInfoChanged = useCallback((oldInfo, newInfo) => {
    return (
      oldInfo.panoId !== newInfo.panoId ||
      oldInfo.heading !== newInfo.heading ||
      oldInfo.pitch !== newInfo.pitch ||
      oldInfo.zoom !== newInfo.zoom ||
      oldInfo.lat !== newInfo.lat ||
      oldInfo.lng !== newInfo.lng ||
      oldInfo.isVisible !== newInfo.isVisible
    );
  }, []);

  const handleDetect = useCallback(async () => {
    if (!streetViewInfoRef.current.isVisible || !streetViewRef.current || !streetViewInfoRef.current.panoId) {
      return;
    }

    const file = await takeScreenshot(streetViewRef, streetViewInfoRef, API_KEY);
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('conf', confRef.current);
    formData.append('iou', iouRef.current);

    try {
      const response = await axios.post(
        'https://fastapi-obj-det-124820880780.us-central1.run.app/predict_detection',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const filteredPredictions = response.data.predictions.filter(pred => {
        const [_, y1] = pred.bbox;
        return y1 < 540;
      });

      setPredictions(filteredPredictions);
    } catch (error) {
      console.error('Error making detection:', error);
    }
  }, []);


  const handlePredictPoles = async () => {
    const file = await takeScreenshot(streetViewRef, streetViewInfoRef, API_KEY);
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(
        'https://fastapi-obj-det-124820880780.us-central1.run.app/predict_pole_class',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setPredictionResult({
        prediction: response.data.class,
        confidence: response.data.confidence.toFixed(4),
      });
    } catch (error) {
      console.error('Error making pole prediction:', error);
    }
  };

  const debouncedHandleDetect = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      handleDetect();
    }, 2000);
  }, [handleDetect]);

  const drawBoxes = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!isStreetViewMode) return;

    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;

    predictions.forEach((pred) => {
      const [x1, y1, x2, y2] = pred.bbox;
      ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
    });
  }, [predictions, isStreetViewMode]);

  useEffect(() => {
    drawBoxes();
  }, [drawBoxes]);


  const handleSearch = useCallback((address) => {
    if (!isLoaded) return;

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const { lat, lng } = results[0].geometry.location;
        positionRef.current = { lat: lat(), lng: lng() };
        
        if (mapRef.current) {
          mapRef.current.panTo(positionRef.current);
        }
        
        if (streetViewRef.current) {
          streetViewRef.current.setPosition(positionRef.current);
        }
        
        setSearchError(null);
      } else {
        setSearchError("Couldn't find the address. Please try again.");
      }
    });
  }, [isLoaded]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onStreetViewLoad = useCallback((streetView) => {
    streetViewRef.current = streetView;

    const updateStreetViewInfo = () => {
      if (!streetViewRef.current) return;

      const isVisible = streetView.getVisible();
      const panoId = streetView.getPano();
      const position = streetView.getPosition();
      const pov = streetView.getPov();
      const zoom = streetView.getZoom();

      const newStreetViewInfo = {
        panoId: panoId,
        isVisible: isVisible,
        heading: pov.heading,
        pitch: pov.pitch,
        zoom: zoom,
        fov: calculateFov(zoom),
        lat: position ? position.lat() : null,
        lng: position ? position.lng() : null,
      };

      if (isStreetViewInfoChanged(streetViewInfoRef.current, newStreetViewInfo)) {
        streetViewInfoRef.current = newStreetViewInfo;
        setIsStreetViewMode(isVisible);

        if (isVisible && panoId) {
          clearPredictions();
          debouncedHandleDetect();
        } else {
          clearPredictions();
        }
      }
    };

    streetView.addListener('visible_changed', updateStreetViewInfo);
    streetView.addListener('pano_changed', updateStreetViewInfo);
    streetView.addListener('position_changed', updateStreetViewInfo);
    streetView.addListener('pov_changed', updateStreetViewInfo);
    streetView.addListener('zoom_changed', updateStreetViewInfo);

    updateStreetViewInfo();
  }, [debouncedHandleDetect, clearPredictions, isStreetViewInfoChanged]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div className="App">
      <SidebarComponent
        confState={confState}
        iouState={iouState}
        setConfState={setConfState}
        setIouState={setIouState}
        handleDetect={handleDetect}
        handlePredictPoles={handlePredictPoles}
        streetViewInfoRef={streetViewInfoRef}
        predictionResult={predictionResult}
        confRef={confRef}
        iouRef={iouRef}
        onSearch={handleSearch}
      />
      {searchError && <div className="search-error">{searchError}</div>}
      <MapComponent
        position={positionRef.current}
        onMapLoad={onMapLoad}
        onStreetViewLoad={onStreetViewLoad}
        isStreetViewMode={isStreetViewMode}
        canvasRef={canvasRef}
        streetViewInfoRef={streetViewInfoRef}
      />
    </div>
  );
}

export default App;