// src/utils/streetViewUtils.js
import axios from 'axios';

export const calculateFov = (zoom) => {
  return Math.round(180 / Math.pow(2, zoom));
};

export const takeScreenshot = async (streetViewRef, streetViewInfoRef, API_KEY) => {
  if (streetViewRef.current && streetViewInfoRef.current.panoId) {
    const panorama = streetViewRef.current;
    const currentPov = panorama.getPov();
    const currentZoom = panorama.getZoom();

    const currentFov = calculateFov(currentZoom);

    const params = {
      size: '640x640',
      pano: streetViewInfoRef.current.panoId,
      fov: currentFov,
      heading: Math.round(currentPov.heading),
      pitch: Math.round(currentPov.pitch),
      key: API_KEY,
    };

    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/streetview', {
        params: params,
        responseType: 'blob',
      });
      const blob = response.data;
      return new File([blob], 'screenshot.png', { type: 'image/png' });
    } catch (error) {
      console.error('Error fetching Street View image:', error);
      return null;
    }
  } else {
    console.error('Street View reference or panoId is missing');
    return null;
  }
};
